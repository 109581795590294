import React from "react"
import { PageHero } from "../components/hero/PageHero"
import { Section } from "../layout/surfaces/Section"
import { Container } from "../layout/surfaces/Container"
import { Heading } from "../components/typogarphy/Heading"
import { Paragraph } from "../components/typogarphy/Paragraph"

const TermsPage = () => {
  return (
    <>
      <PageHero type="services" />
      <Section>
        <Container>
          <Paragraph>
            Please take the time to read these terms and conditions. These terms
            and conditions govern your use of this website; by using this
            website, you accept these terms and conditions in full. If you
            disagree with these terms and conditions or any part of these terms
            and conditions, you must not use this website.
          </Paragraph>
          <Paragraph>
            If you purchase products through our Website, there will be
            additional terms and conditions relating to the purchase. Please
            make sure you agree with these terms and conditions, which you will
            be directed to read prior to making your purchase.
          </Paragraph>
          <Paragraph>
            This website uses cookies. By using this website and agreeing to
            these terms and conditions, you consent to Deyst Solutions&#39; use
            of cookies in accordance with the terms of Deyst Solutions&#39;
            privacy policy.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Definitions
          </Heading>
          <Paragraph>
            Services means software development and business analysis services.
            The Website means the website deyst.com.au We / Us etc means Deyst
            and any subsidiaries, affiliates, employees, officers, agents or
            assigns.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Deyst’s details
          </Heading>
          <Paragraph>
            Deyst and Deyst Solutions is used interchangeably. Deyst is
            registered in Australia under registration number ABN 41 490 992
            617. You can contact Deyst by email: admin@deyst.com.au
          </Paragraph>
          <Heading variant="h2" size="h3">
            Accuracy of content
          </Heading>
          <Paragraph>
            We have taken proper care and precautions to ensure that the
            information we provide on this Website is accurate. However, we
            cannot guarantee, nor do we accept any legal liability arising from
            or connected to, the accuracy, reliability, currency or completeness
            of anything contained on this Website or on any linked site. The
            information contained on this Website should not take the place of
            professional advice.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Use of Website
          </Heading>
          <Paragraph>
            The Website is made available for your use on your acceptance and
            compliance with these terms and conditions. By using this Website,
            you are agreeing to these terms and conditions.
          </Paragraph>
          <Paragraph>
            You agree that you will use this website in accordance with all
            applicable local, state, national and international laws, rules and
            regulations.
          </Paragraph>
          <Paragraph>
            You agree that you will not use, nor will you allow or authorise any
            third party to use, the Website for any purpose that is unlawful,
            defamatory, harassing, abusive, fraudulent or obscene way or in any
            other inappropriate way or in a way which conflicts with the Website
            or the Services.
          </Paragraph>
          <Paragraph>
            If you contribute to our forum (if any) or make any public comments
            on this Website which are, in our opinion, unlawful, defamatory,
            harassing, abusive, fraudulent or obscene or in any other way
            inappropriate or which conflict with the Website or the Services
            offered, then we may at our discretion, refuse to publish such
            comments and/or remove them from the Website.
          </Paragraph>
          <Paragraph>
            We reserve the right to refuse or terminate service to anyone at any
            time without notice or reason.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Passwords and logins
          </Heading>
          <Paragraph>
            You are responsible for maintaining the confidentiality of your
            passwords and login details and for all activities carried out under
            your password and login.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Indemnification for loss or damage
          </Heading>
          <Paragraph>
            You agree to indemnify Us and hold Us harmless from and against any
            and all liabilities or expenses arising from or in any way related
            to your use of this Website or the Services or information offered
            on this Website, including any liability or expense arising from all
            claims, losses, damages (actual and consequential), suits,
            judgments, litigation costs and solicitors fees of every kind and
            nature incurred by you or any third parties through you.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Intellectual property and copyrights
          </Heading>
          <Paragraph>
            We hold the copyright to the content of this Website, including all
            uploaded files, layout design, data, graphics, articles, file
            content, codes, news, tutorials, videos, reviews, forum posts and
            databases contained on the Website or in connection with the
            Services. You must not use or replicate our copyright material other
            than as permitted by law. Specifically, you must not use or
            replicate our copyright material for commercial purposes unless
            expressly agreed to by Us, in which case we may require you to sign
            a Licence Agreement.
          </Paragraph>
          <Paragraph>
            If you wish to use content, images or other of our intellectual
            property, you should submit your request to us at the following
            email address: deystAU@gmail.com
          </Paragraph>
          <Heading variant="h2" size="h3">
            Trademarks
          </Heading>
          <Paragraph>
            The trademarks and logos contained on this Website are trademarks of
            Deyst. Use of these trademarks is strictly prohibited except with
            Our express, written consent.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Links to external websites
          </Heading>
          <Paragraph>
            This Website may contain links that direct you outside of this
            Website. These links are provided for your convenience and are not
            an express or implied indication that we endorse or approve of the
            linked Website, its contents or any associated website, product or
            service. We accept no liability for loss or damage arising out of or
            in connection to your use of these sites.
          </Paragraph>
          <Paragraph>
            You may link to our articles or home page. However, you should not
            provide a link which suggests any association, approval or
            endorsement on our part in respect to your website, unless we have
            expressly agreed in writing. We may withdraw our consent to you
            linking to our site at any time by notice to you.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Limitation of Liability
          </Heading>
          <Paragraph>
            We take no responsibility for the accuracy of any of the content or
            statements contained on this Website or in relation to our Services.
            Statements made are by way of general comment only and you should
            satisfy yourself as to their accuracy. Further, all of our Services
            are provided without a warranty with the exception of any warranties
            provided by law. We are not liable for any damages whatsoever,
            incurred as a result of or relating to the use of the Website or our
            Services.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Information Collection
          </Heading>
          <Paragraph>
            Use of information you have provided us with, or that we have
            collected and retained relating to your use of the Website and/or
            our Services, is governed by our Privacy Policy. By using this
            Website and the Services associated with this Website, you are
            agreeing to the Privacy Policy. To view our Privacy Policy and read
            more about why we collect personal information from you and how we
            use that information, click here.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Confidentiality
          </Heading>
          <Paragraph>
            All personal information you give us will be dealt with in a
            confidential manner in accordance with our Privacy Policy. However,
            due to circumstances outside of our control, we cannot guarantee
            that all aspects of your use of this Website will be confidential
            due to the potential ability of third parties to intercept and
            access such information.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Law and jurisdiction
          </Heading>
          <Paragraph>
            These terms and conditions will be governed by and construed in
            accordance with Australian Law and any disputes relating to these
            terms and conditions will be subject to the exclusive jurisdiction
            of the courts of Australia. We retain the right to bring proceedings
            against you for breach of these Terms and Conditions, in your
            country of residence or any other appropriate country or
            jurisdiction.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Severability
          </Heading>
          <Paragraph>
            If a provision of these terms and conditions is determined by any
            court or other competent authority to be unlawful and/or
            unenforceable, the other provisions will continue in effect. If any
            unlawful and/or unenforceable provision would be lawful or
            enforceable if part of it were deleted, that part will be deemed to
            be deleted, and the rest of the provision will continue in effect.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Breaches of these terms and conditions
          </Heading>
          <Paragraph>
            Without prejudice to Deyst’s other rights under these terms and
            conditions, if you breach these terms and conditions in any way,
            Deyst may take such action as we deem appropriate to deal with the
            breach, including suspending your access to the website, prohibiting
            you from accessing the website, blocking computers using your IP
            address from accessing the website, contacting your internet service
            provider to request that they block your access to the website
            and/or bringing court proceedings against you.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Variation
          </Heading>
          <Paragraph>
            Deyst may revise these terms and conditions from time-to-time.
            Revised terms and conditions will apply to the use of this website
            from the date of the publication of the revised terms and conditions
            on this website. Please check this page regularly to ensure you are
            familiar with the current version. These terms and conditions
            constitute the entire agreement in relation to your use of this
            website and supersede all previous agreements in respect of your use
            of this website.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Copyright
          </Heading>
          <Paragraph>© Deyst Solutions 2020</Paragraph>
          <Paragraph>
            Except as permitted by the copyright law applicable to you, you may
            not reproduce or communicate any of the content on this website,
            including files downloadable from this website, without the
            permission of the copyright owner. The Australian Copyright Act
            allows certain uses of content from the internet without the
            copyright owner’s permission. This includes uses by educational
            institutions and by Commonwealth and State governments, provided
            fair compensation is paid. For more information, see
            www.copyright.com.au and www.copyright.org.au. The owners of
            copyright in the content on this website may receive compensation
            for the use of their content by educational institutions and
            governments, including from licensing schemes managed by Copyright
            Agency. We may change these terms of use from time to time. Check
            before re-using any content from this website.
          </Paragraph>
        </Container>
      </Section>
    </>
  )
}

export default TermsPage
